import React, {useState} from 'react'
import {NavItem} from 'reactstrap'
import {Link, useLocation} from 'react-router-dom'
import {FiHome, FiUsers, FiArchive, FiCheckSquare} from 'react-icons/fi' // Updated icon import
import {AppPaths} from '../AppPaths'

const CustomLink: React.FC<{to: string, children: React.ReactNode, icon: React.ReactNode}> = ({to, children, icon}) =>
{
  const location = useLocation()
  const isActive = location.pathname === to

  return (
    <Link
      style={{
        textDecoration: 'none',
        color: isActive ? '#000' : 'inherit', // Change color to black when active
        fontWeight: isActive ? 'bold' : 'normal',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
        fontSize: '0.8rem',
      }}
      to={to}
    >
      <div
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          backgroundColor: isActive ? '#000' : 'transparent', // Fill background with black when active
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'background-color 0.3s ease',
        }}
      >
        {React.cloneElement(icon as React.ReactElement, {
          size: 24,
          color: isActive ? '#ffffff' : 'inherit', // Change icon color to white when active
        })}
      </div>
      <span>{children}</span>
    </Link>
  )
}

export const NavMenu: React.FC = () =>
{
  const [collapsed, setCollapsed] = useState(true)

  const toggleNavbar = () =>
  {
    setCollapsed(!collapsed)
  }

  return (
    <header
      style={{
        width: '100%',
        position: 'fixed', // Anchors the nav bar to the top of the page
        top: 0,
        left: 0,
        backgroundColor: '#f5f5f5',
        boxShadow: '0 0.25rem 0.75rem rgba(0, 0, 0, 0.05)',
        zIndex: 1000,
        paddingTop: '0.5rem', // Space at the top
        paddingBottom: '0.5rem', // Space at the bottom
      }}
    >
      <nav
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          height: '70px', // Increased the height for more space around the items
        }}
      >
        <NavItem style={{listStyleType: 'none'}}> {/* Remove the bullet marker */}
          <CustomLink to={AppPaths.Home} icon={<FiHome />}>
            Home
          </CustomLink>
        </NavItem>
        <NavItem style={{listStyleType: 'none'}}> {/* Remove the bullet marker */}
          <CustomLink to={AppPaths.VotePilot} icon={<FiCheckSquare />}>
            VotePilot
          </CustomLink>
        </NavItem>
        <NavItem style={{listStyleType: 'none'}}> {/* Remove the bullet marker */}
          {/* Updated label and icon for Connect */}
          <CustomLink to={AppPaths.ContactUs} icon={<FiUsers />}>
            Connect
          </CustomLink>
        </NavItem>
      </nav>
    </header>
  )
}