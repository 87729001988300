// src/components/Home.tsx

import React, {FC, CSSProperties} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useMediaQuery} from 'react-responsive'
import {FaMobileAlt} from 'react-icons/fa'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import logo from "../images/Logo2.jpg"
import {useEffect} from 'react'

export const Home: FC = () =>
{
  const navigate = useNavigate()
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

  const handleTryVotePilot = () => navigate('/vote-pilot')

  const location = useLocation()

  useEffect(() =>
  {
    window.scrollTo(0, 0) // Resets the scroll position to the top
  }, [location])

  // Button Component
  const Button: FC<{
    onClick: () => void
    label: string
    icon?: React.ReactNode
    ariaLabel: string
  }> = ({onClick, label, icon, ariaLabel}) =>
    {
      const buttonStyles: CSSProperties = {
        padding: isMobile ? "10px 20px" : "12px 24px",
        fontSize: isMobile ? "16px" : "18px",
        borderRadius: "24px",
        backgroundColor: "#000", // Black color for buttons
        color: "#fff",
        border: "none",
        cursor: "pointer",
        display: "inline-flex", // Changed to inline-flex for better control of icon alignment
        alignItems: "center", // Ensures vertical alignment
        justifyContent: "center",
        marginTop: isMobile ? "14px" : "20px", // Reduced by ~30% for mobile
        width: "auto",
      }

      const iconStyles: CSSProperties = {
        display: "inline-block",
        lineHeight: 0, // Adjust line height for better vertical alignment
      }

      return (
        <button style={buttonStyles} onClick={onClick} aria-label={ariaLabel}>
          <span style={{display: "flex", alignItems: "center", gap: "8px"}}>
            {label}
            {icon && <span style={iconStyles}>{icon}</span>}
          </span>
        </button>
      )
    }

  // Styles
  const styles: {[key: string]: CSSProperties} = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: isMobile ? '17.5px 14px' : '25px 20px', // Reduced by ~30% for mobile
      fontFamily: 'Helvetica Neue, sans-serif',
      color: '#000',
    },
    header: {
      textAlign: 'center',
      marginBottom: isMobile ? '42px' : '60px', // Reduced by ~30% for mobile
    },
    logo: {
      width: '80px',
      height: '80px',
      marginBottom: isMobile ? '14px' : '20px', // Reduced by ~30% for mobile
      objectFit: 'cover', // Ensures the image covers the container without distortion
      borderRadius: '50%', // Makes the logo circular (optional)
    },
    title: {
      fontSize: isMobile ? '28px' : '36px',
      fontWeight: 'bold',
      marginBottom: isMobile ? '14px' : '20px', // Reduced by ~30% for mobile
    },
    subtitle: {
      fontSize: isMobile ? '18px' : '24px',
      marginBottom: isMobile ? '28px' : '40px', // Reduced by ~30% for mobile
      color: '#555', // Optional: Add color for better aesthetics
    },
    section: {
      marginBottom: isMobile ? '42px' : '60px', // Reduced by ~30% for mobile
    },
    sectionTitle: {
      fontSize: isMobile ? '24px' : '28px',
      fontWeight: 'bold',
      marginBottom: isMobile ? '14px' : '20px', // Reduced by ~30% for mobile
      textAlign: 'center',
    },
    text: {
      fontSize: isMobile ? '16px' : '18px',
      lineHeight: 1.6,
      textAlign: 'center',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: isMobile ? '14px' : '15px', // Reduced by ~30% for mobile
    },
  }

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        {/* Logo */}
        <LazyLoadImage
          src={logo}
          alt='Logo'
          style={styles.logo}
          effect='blur'
          onError={(e) =>
          {
            e.currentTarget.onerror = null // Prevents infinite loop if fallback fails
            e.currentTarget.src = '/fallback-logo.png' // Path to the fallback imagep
          }}
        />
        <h1 style={styles.title}>Direct Democracy Corporation</h1>
        {/* Conditionally render the subtitle only on non-mobile devices */}

        <p style={styles.subtitle}>
          Crafting the Future of Governance
        </p>
      </header>


      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>VotePilot</h2>
        <p style={styles.text}>
          Connect • Propose • Decide
        </p>
        <div style={styles.buttonContainer}>
          <Button
            onClick={handleTryVotePilot}
            label='Start Now'
            icon={<FaMobileAlt />}
            ariaLabel='Start Now'
          />
        </div>
      </section>
    </div>
  )
}

export default Home