import React, {useState, useEffect} from 'react'
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa'

// Local images
import Image1 from '../images/11.jpg'
import Image2 from '../images/22.jpg'
import Image3 from '../images/33.jpg'
import Image4 from '../images/44.jpg'
import Image5 from '../images/55.jpg'

const images = [Image1, Image2, Image3, Image4, Image5]

const ImageCarousel = () =>
{
    const [isMobile, setIsMobile] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() =>
    {
        const updateIsMobile = () =>
        {
            setIsMobile(window.innerWidth < 600)
        }
        updateIsMobile()
        window.addEventListener('resize', updateIsMobile)
        return () => window.removeEventListener('resize', updateIsMobile)
    }, [])

    // Pages based on device
    // Desktop: 
    //   Page 1: [0,1,2] (3 images)
    //   Page 2: [3,4] (2 images)
    // Mobile:
    //   Each page: a single image
    const pages = isMobile
        ? images.map(img => [img])
        : [
            [images[0], images[1], images[2]],
            [images[3], images[4]],
        ]

    const totalPages = pages.length
    const currentPageImages = pages[currentIndex]

    const canGoPrev = currentIndex > 0
    const canGoNext = currentIndex < totalPages - 1

    const prev = () =>
    {
        if (canGoPrev) setCurrentIndex(currentIndex - 1)
    }

    const next = () =>
    {
        if (canGoNext) setCurrentIndex(currentIndex + 1)
    }

    // Minimal, Jony Ive–inspired styling
    const containerStyle = {
        position: 'relative' as const,
        maxWidth: isMobile ? '300px' : '600px',
        margin: '0 auto',
        padding: '20px 0',
        backgroundColor: 'transparent',
        textAlign: 'center' as const,
        fontFamily: 'sans-serif',
    }

    // On desktop, we want consistent sizing. We'll always use a fixed width for images:
    // For 3 images, ~32% each leaves room for gaps.
    // For 2 images, we still keep them at 32% each, simply centered.
    const desktopImageWidth = '32%'

    const imageContainerStyle = {
        display: 'flex',
        justifyContent: isMobile
            ? 'center'
            : currentPageImages.length < 3
                ? 'center' // center if we have fewer than 3 images
                : 'space-between',
        gap: currentPageImages.length < 3 ? '10px' : '5px',
        alignItems: 'center',
    } as const

    const imageStyle = {
        width: isMobile ? '100%' : desktopImageWidth,
        height: 'auto',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    } as const

    const buttonCommonStyle = {
        background: '#fff',
        border: 'none',
        cursor: 'pointer',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#888',
        fontSize: '18px',
        transition: 'background 0.3s',
    } as const

    const buttonDisabledStyle = {
        opacity: 0.3,
        cursor: 'default',
    }

    const desktopArrowStyle = {
        position: 'absolute' as const,
        top: '50%',
        transform: 'translateY(-50%)',
    }

    const prevButtonDesktopStyle = {
        ...buttonCommonStyle,
        ...desktopArrowStyle,
        left: '-50px',
    }

    const nextButtonDesktopStyle = {
        ...buttonCommonStyle,
        ...desktopArrowStyle,
        right: '-50px',
    }

    return (
        <div style={containerStyle}>
            <div style={imageContainerStyle}>
                {currentPageImages.map((img, idx) => (
                    <img key={idx} src={img} alt="" style={imageStyle} />
                ))}
            </div>

            {isMobile ? (
                <div
                    style={{
                        marginTop: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '10px',
                    }}
                >
                    <button
                        onClick={prev}
                        disabled={!canGoPrev}
                        style={{
                            ...buttonCommonStyle,
                            ...(canGoPrev ? {} : buttonDisabledStyle),
                        }}
                    >
                        <FaChevronLeft />
                    </button>
                    <button
                        onClick={next}
                        disabled={!canGoNext}
                        style={{
                            ...buttonCommonStyle,
                            ...(canGoNext ? {} : buttonDisabledStyle),
                        }}
                    >
                        <FaChevronRight />
                    </button>
                </div>
            ) : (
                <>
                    <button
                        onClick={prev}
                        disabled={!canGoPrev}
                        style={{
                            ...prevButtonDesktopStyle,
                            ...(canGoPrev ? {} : buttonDisabledStyle),
                        }}
                        aria-label="Previous"
                    >
                        <FaChevronLeft />
                    </button>
                    <button
                        onClick={next}
                        disabled={!canGoNext}
                        style={{
                            ...nextButtonDesktopStyle,
                            ...(canGoNext ? {} : buttonDisabledStyle),
                        }}
                        aria-label="Next"
                    >
                        <FaChevronRight />
                    </button>
                </>
            )}
        </div>
    )
}

export default ImageCarousel