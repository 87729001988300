export class AppPaths
{
    static Home: string = "/"
    static FutureOfDemocracy: string = "/future-of-democracy"
    static OurMission: string = "/our-mission"
    static ContactUs: string = "/contact-us"
    static NewsAndUpdates: string = "/news-and-updates"
    static ReferendumPage: string = "/prototype"
    static VoteHistory: string = "/vote-history"
    static ReferendumListPage: string = "/education"

    static TaxReferendum: string = "/prototype/tax-referendum"
    static EducationReferendum: string = "/prototype/education-referendum"
    static HealthcareReferendum: string = "/prototype/healthcare-referendum"
    static EnvironmentReferendum: string = "/prototype/environment-referendum"
    static HousingReferendum: string = "/prototype/housing-referendum"
    static ForeignPolicyReferendum: string = "/prototype/foreign-policy-referendum"

    static TaxInsights: string = "/prototype/tax-insights"
    static EducationInsights: string = "/prototype/education-insights"
    static HealthcareInsights: string = "/prototype/healthcare-insights"
    static EnvironmentInsights: string = "/prototype/environment-insights"
    static HousingInsights: string = "/prototype/housing-insights"
    static ForeignPolicyInsights: string = "/prototype/foreign-policy-insights"
    static Support: string = "/support"
    static VotePilot: string = "/vote-pilot"
    static PrivacyPolicy: string = "/privacy-policy"
    static AccountDeletion: string = "/account-deletion"
    static TermsOfService: string = "/terms-of-service"
    static PrivacyPolicyScreenAndroid: string = "/privacy-policy-android"
}