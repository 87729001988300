import React, {useState, useEffect, useRef} from 'react'
import {getDocument, GlobalWorkerOptions, PDFDocumentProxy} from 'pdfjs-dist'
import styled from 'styled-components'

// Set the worker source
GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`

// Styled Components

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background-color: #f5f5f5;
    padding: 20px;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    flex-direction: column; /* Default to vertical stacking */

    @media (min-width: 767px) and (max-width: 990px) {
        flex-direction: row; /* For medium screens, align elements horizontally */
        flex-wrap: wrap; /* Ensure elements wrap to the next line if needed */
        gap: 20px; /* Add spacing between items */
    }

    @media (min-width: 991px) {
        flex-direction: row; /* Horizontal alignment for large screens */
    }
`

const NavButton = styled.button<{disabled: boolean}>`
    background-color: ${({disabled}) => (disabled ? '#e0e0e0' : '#ffffff')};
    border: 1px solid #dcdcdc;
    border-radius: 50%;
    cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
    padding: 15px;
    width: 50px;
    height: 50px;
    box-shadow: ${({disabled}) => (disabled ? 'none' : '0 4px 6px rgba(0, 0, 0, 0.1)')};
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;

    &:hover {
        background-color: ${({disabled}) => (disabled ? '#e0e0e0' : '#f0f0f0')};
        transform: ${({disabled}) => (disabled ? 'none' : 'scale(1.05)')};
        box-shadow: ${({disabled}) => (disabled ? 'none' : '0 6px 8px rgba(0, 0, 0, 0.15)')};
    }

    &:active {
        transform: ${({disabled}) => (disabled ? 'none' : 'scale(0.95)')};
        box-shadow: ${({disabled}) => (disabled ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.1)')};
    }

    svg {
        width: 20px;
        height: 20px;
        fill: #555555;
        transition: fill 0.3s ease;
    }

    &:hover svg {
        fill: #000000;
    }

    @media (min-width: 767px) and (max-width: 990px) {
        padding: 10px;
        width: 40px;
        height: 40px; /* Make buttons smaller for medium screens */
    }

    @media (min-width: 991px) {
        padding: 20px;
        width: 60px;
        height: 60px; /* Larger buttons for large screens */
    }
`

// Removed duplicate CanvasContainer declaration

const CanvasContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 800px;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dcdcdc;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    margin: 20px 0;

    canvas {
        max-width: 100%;
        max-height: 100%;
        border-radius: 12px;
    }

    @media (min-width: 768px) {
        height: 80%;
        margin: 0 20px;
    }
`

const PDFViewer: React.FC = () =>
{
    const [pdf, setPdf] = useState<PDFDocumentProxy | null>(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const canvasRef = useRef<HTMLCanvasElement>(null)

    const pdfUrl = `${process.env.PUBLIC_URL}/executive-summary.pdf`

    useEffect(() =>
    {
        const loadPdf = async () =>
        {
            try
            {
                const pdfDoc = await getDocument(pdfUrl).promise
                setPdf(pdfDoc)
                setTotalPages(pdfDoc.numPages)
                renderPage(pdfDoc, 1)
            } catch (error)
            {
                console.error('Error loading PDF:', error)
            }
        }

        loadPdf()
    }, [pdfUrl])

    const renderPage = async (pdfDoc: PDFDocumentProxy, pageNumber: number) =>
    {
        const page = await pdfDoc.getPage(pageNumber)
        const canvas = canvasRef.current

        if (canvas)
        {
            const context = canvas.getContext('2d')
            if (context)
            {
                const containerWidth = canvas.parentElement!.offsetWidth
                const viewport = page.getViewport({scale: 1})
                const scale = containerWidth / viewport.width
                const scaledViewport = page.getViewport({scale})

                canvas.width = scaledViewport.width
                canvas.height = scaledViewport.height

                const renderContext = {
                    canvasContext: context,
                    viewport: scaledViewport,
                }
                await page.render(renderContext).promise
            }
        }
    }

    const goToPreviousPage = () =>
    {
        if (pdf && currentPage > 1)
        {
            const newPage = currentPage - 1
            setCurrentPage(newPage)
            renderPage(pdf, newPage)
        }
    }

    const goToNextPage = () =>
    {
        if (pdf && currentPage < totalPages)
        {
            const newPage = currentPage + 1
            setCurrentPage(newPage)
            renderPage(pdf, newPage)
        }
    }

    useEffect(() =>
    {
        const handleOrientationChange = () =>
        {
            if (pdf)
            {
                renderPage(pdf, currentPage)
            }
        }

        window.addEventListener('orientationchange', handleOrientationChange)

        return () =>
        {
            window.removeEventListener('orientationchange', handleOrientationChange)
        }
    }, [pdf, currentPage])

    useEffect(() =>
    {
        const handleKeyDown = (event: KeyboardEvent) =>
        {
            if (event.key === 'ArrowLeft')
            {
                goToPreviousPage()
            } else if (event.key === 'ArrowRight')
            {
                goToNextPage()
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () =>
        {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [currentPage, totalPages])

    useEffect(() =>
    {
        const handleInitialScroll = () =>
        {
            // Scroll the container down by 50 pixels
            window.scrollBy({top: 50, behavior: 'smooth'})
        }

        handleInitialScroll()
    }, [])

    return (
        <Container>
            {/* Previous Page Button */}
            <NavButton
                onClick={goToPreviousPage}
                disabled={currentPage === 1}
                aria-label="Previous Page"
            >
                {/* Left Arrow Icon */}
                <svg viewBox="0 0 20 20">
                    <path d="M12.707 14.707a1 1 0 01-1.414 0L5.586 9l5.707-5.707a1 1 0 011.414 1.414L8.414 9l4.293 4.293a1 1 0 010 1.414z" />
                </svg>
            </NavButton>

            {/* PDF Canvas */}
            <CanvasContainer>
                <canvas ref={canvasRef} />
            </CanvasContainer>

            {/* Next Page Button */}
            <NavButton
                onClick={goToNextPage}
                disabled={currentPage === totalPages}
                aria-label="Next Page"
            >
                {/* Right Arrow Icon */}
                <svg viewBox="0 0 20 20">
                    <path d="M7.293 14.707a1 1 0 001.414 0L14.414 9l-5.707-5.707a1 1 0 00-1.414 1.414L11.586 9l-4.293 4.293a1 1 0 000 1.414z" />
                </svg>
            </NavButton>
        </Container>
    )
}

export default PDFViewer